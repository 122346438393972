<template>
  <div class="moderation">
    <NavBar text="Accueil de la plateforme" link="/app-home" :has-nav="true"/>
    <div class="line">
      <div class="button" @click="() => filter = 'new'" :class="{active: filter == 'new'}">
        Nouveaux
      </div>
      <div class="button" @click="() => filter = 'accepted'" :class="{active: filter == 'accepted'}">
        Validés
      </div>
      <div class="button" @click="() => filter = 'refused'" :class="{active: filter == 'refused'}">
        Refusés
      </div>
    </div>
    <Message v-if="message.status == filter" v-for="message in messages" :key="message.idMessage" :message="message"/>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import Message from "../components/Message";
export default {
  name: "Moderation",
  components: {NavBar, Message},
  data(){
    return {
      messages: [],
      filter: 'new'
    }
  },
  mounted(){
    this.getMessages()
  },
  methods: {
    async getMessages(){
      const { data } = await this.$axios.post('/moderation/getMessages.php', {
        app: this.$store.getters.app.code
      })
      this.messages = data
    },
  }
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;
.document-list {
  width: 70%;
}

.line {
  display: flex;
  align-items: center;

  .button {
    width: 30%;

    &.active {
      background-color: $blue;
      color: white;
    }
  }
}
</style>