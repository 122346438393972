import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import Login from '@/views/Login'
import AppHome from "../views/AppHome";
import Content from "../views/Content";
import Users from "../views/Users";
import Speakers from "@/views/Speakers";
import Documents from "@/views/Documents";
import Moderation from "@/views/Moderation";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/app-home',
    name: 'AppHome',
    component: AppHome
  },
  {
    path: '/content',
    name: 'Content',
    component: Content
  },
  {
    path: '/users',
    name: 'Users',
    component: Users
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/documents',
    name: 'Documents',
    component: Documents
  },
  {
    path: '/moderation',
    name: 'Moderation',
    component: Moderation
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
