<template>
  <div class="speaker">
    <img @click="openInput" :src="speaker.img_url == null ? 'https://180dc.org/wp-content/uploads/2017/11/profile-placeholder-300x237.png' : 'https://medias-storage.blackmoonlab.fr'+speaker.img_url" alt="">
    <div>
      <input type="text" v-model="speaker.name" @input="updateSpeaker">
      <br>
      <input v-model="speaker.bio" @input="updateSpeaker">
      <input type="file" ref="file" style="display:none" @change="uploadPic">
      <div class="button" @click="deleteSpeaker">
        Supprimer
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Speaker",
  props: ['speaker'],
  methods: {
    async deleteSpeaker(){
      const { data } = await this.$axios.post('/speakers/deleteSpeaker.php', {
        app: this.$store.getters.app.code,
        idSpeaker: this.speaker.idSpeaker
      })
      this.$emit('delete')
    },
    async updateSpeaker(){
      const { data } = await this.$axios.post('/speakers/updateSpeaker.php', {
        app: this.$store.getters.app.code,
        speaker: this.speaker
      })
    },
    openInput(){
      this.$refs.file.click()
    },
    async uploadPic(){
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('app', this.$store.getters.app.code)
      formData.append('idSpeaker', this.speaker.idSpeaker)
      const { data } = await this.$axios.post('/speakers/uploadPic.php', formData)
      this.file = ''
      this.speaker.img_url = data
      this.$refs.file.value = null
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;
  .speaker {
    width: 100%;
    display: flex;
    align-items: center;
    height: 20vh;
    padding: 20px;
    border: solid 2px $blue;
    border-radius: 20px;
    margin-bottom: 20px;

      >div {
        flex-grow: 1;
      }

     img {
       height: 15vh;
       width: 15vh;
       border-radius: 1000px;
       border: solid 1px $blue;
       object-fit: contain;
       margin-right: 25px;
       cursor: pointer;
     }

      input {
        border: none;
        width: 100%;
        margin-bottom: 15px;
        color: #AAAAAA;

        &:first-child {
          color: $blue;
          font-weight: 700;
          font-size: 1.3rem;
        }
      }
  }

.button {
  color: $red;
  border-color: $red;
  width: fit-content;
  margin-left: 0;
}

.button:hover {
  background-color: $red;
  color: white;
}
</style>