<template>
  <div class="users">
    <NavBar text="Accueil de la plateforme" link="/app-home" :has-nav="true"/>
    <div class="content">
      <div class="left">
        <template v-if="creating">
          <h2>Créer un nouvel utilisateur</h2>
          <br>
          <div class="field">
            <p>Email</p>
            <input type="text" v-model="newUser.email">
          </div>
          <div class="field">
            <p>Nom</p>
            <input type="text" v-model="newUser.name">
          </div>
          <div class="field">
            <p>Prénom</p>
            <input type="text" v-model="newUser.first_name">
          </div>
          <div class="line">
            <div class="field">
              <div class="button" @click="createUser">Créer</div>
            </div>
            <div class="field">
              <div class="button" @click="unsetCreating">Annuler</div>
            </div>
          </div>
        </template>
        <template v-if="!creating">
          <User v-on:delete="() => deleteUser(index)" :user="user" v-for="(user, index) in users" :key="user.idUser"/>
        </template>
      </div>
      <div class="right">
        <div class="button" @click="setCreating">
          Ajouter un utilisateur
        </div>
        <div class="button" @click="clickInput">
          Importer des utilisateurs
        </div>
        <input type="file" ref="file" style="display:none" @change="importUsers">
        <div class="button" @click="exportUser">
          Exporter les utilisateurs
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import User from "../components/User";
export default {
  name: "Users",
  components: {User, NavBar},
  data(){
    return {
      creating: false,
      users: [],
      file: '',
      newUser: {
        email: '',
        name: '',
        first_name: ''
      }
    }
  },
  async mounted() {
    this.loadUsers()
  },
  methods: {
    deleteUser(index){
      this.users.splice(index, 1)
    },
    setCreating(){
      this.creating = true
    },
    unsetCreating(){
      this.creating = false
    },
    async createUser(){
      if(this.newUser.email != '' && this.newUser.name != '' && this.newUser.first_name != ''){
        const { data } = await this.$axios.post('users/createUser.php', {
          app: this.$store.getters.app.code,
          user: this.newUser
        })

        this.newUser = {
          email: '',
          name: '',
          first_name: ''
        }
        this.users.push(data)
        this.unsetCreating()
      }
    },
    exportUser(){
      window.open('https://api-admin-novartis.blackmoonlab.fr/users/exportUser.php?app=' + this.$store.getters.app.code, '_blank').focus();
    },
    clickInput(){
      this.$refs.file.click()
    },
    async importUsers(){
      this.file = this.$refs.file.files[0];
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('app', this.$store.getters.app.code)
      const { data } = await this.$axios.post('/users/importUsers.php', formData)
      this.file = ''
      this.loadUsers()
    },
    async loadUsers(){
      const { data } = await this.$axios.post('/users/getUsers.php', {
        app: this.$store.getters.app.code
      })
      this.users = data
      this.$refs.file.value = null
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;

.content {
  display: flex;
  justify-content: space-between;
}

.left {
  width: 75%;
}

.right {
  width: 20%;
}

.button {
  padding: 5px 10px;
  border: solid 2px $blue;
  text-align: center;
  font-weight: 700;
  color: $blue;
  border-radius: 100px;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.button:hover {
  color: white;
  background-color: $blue;
}

h2 {
  color: $blue;
}

.field {
  width: 100%;
  margin-bottom: 30px;

  p {
    font-weight: 700;
    color: $blue;
    margin-bottom: 15px;
    font-size: 14px;
  }

  input {
    width: 100%;
    padding: 5px 10px;
    border-radius: 5px;
    border: solid 1px #AAAAAA;
  }
}

.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
   div {
     width: 45%;
   }
}
</style>