<template>
  <div class="user">
    <input type="email" v-model="user.email" @input="updateUser">
    <input type="text" v-model="user.name" @input="updateUser">
    <input type="text" v-model="user.first_name" @input="updateUser">
    <img src="/trash.svg" @click="deleteUser" alt="">
  </div>
</template>

<script>
export default {
  name: "User",
  props: ['user'],
  methods: {
    async deleteUser() {
      const { data } = await this.$axios.post('/users/deleteUser.php', {
        app: this.$store.getters.app.code,
        idUser: this.user.idUser
      })

      this.$emit('delete')
    },
    async updateUser(){
      const { data } = await this.$axios.post('users/updateUser.php', {
        app: this.$store.getters.app.code,
        user: this.user
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  $blue: #1A2A6C;
  $red: #B21F1F;
  $orange: #D15F25;
  $yellow: #FDBB2D;

  .user {
    width: 100%;
    padding: 10px;
    border-radius: 100px;
    border: solid 1px $blue;
    margin-bottom: 20px;
  }

  input {
    border: none;
    color: $blue;

    &:first-child {
      font-weight: 300;
      width: 40%;
    }

    &:not(:first-child){
      font-weight: 700;
      width: 25%;
    }
  }

  img {
    cursor: pointer;
  }
</style>