<template>
  <nav>
    <router-link tag="div" class="backLink" :to="link"><img src="/chevron.svg" alt=""> {{text}}</router-link>
    <div class="links" v-if="hasNav">
      <router-link tag="div" to="/content" class="button" :class="{active: $route.path == '/content'}">
        Contenus
      </router-link>
      <router-link tag="div" to="/users" class="button" :class="{active: $route.path == '/users'}">
        Utilisateurs
      </router-link>
      <router-link tag="div" to="/speakers" class="button" :class="{active: $route.path == '/speakers'}">
        Intervenants
      </router-link>
      <router-link tag="div" to="/documents" class="button"  :class="{active: $route.path == '/documents'}">
        Documents
      </router-link>
      <router-link tag="div" to="/agenda" class="button" :class="{active: $route.path == '/agenda'}">
        Agenda
      </router-link>
      <router-link tag="div" to="/moderation" class="button" :class="{active: $route.path == '/moderation'}">
        Modération
      </router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
  props: ['link', 'text', 'hasNav']
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;
.button {
  padding: 5px 10px;
  border: solid 1px $blue;
  text-align: center;
  font-weight: 500;
  color: $blue;
  border-radius: 100px;
  cursor: pointer;
  margin: 0 auto;
  margin-left: 15px;
}

.button:hover {
  color: white;
  background-color: $blue;
}

.active {
  color: white;
  background-color: $blue;
}
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5vh;
  }

  .links {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .backLink {
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      height: 16px;
      margin-right: 20px;
      object-fit: contain;
    }
  }
</style>
