<template>
  <div class="speakers">
    <NavBar text="Accueil de la plateforme" link="/app-home" :has-nav="true"/>
    <div class="speaker-list">
      <div class="button" @click="createSpeaker">
        Ajouter un speaker
      </div>
      <Speaker v-on:delete="() => deleteSpeaker(index)" v-for="(speaker, index) in speakers" :key="speaker.idSpeaker" :speaker="speaker" />
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import Speaker from "../components/Speaker";
export default {
  name: "Speakers",
  components: {NavBar, Speaker},
  data(){
    return {
      speakers: []
    }
  },
  mounted(){
    this.getSpeakers()
  },
  methods: {
    async getSpeakers(){
      const { data } = await this.$axios.post('/speakers/getSpeakers.php', {
        app: this.$store.getters.app.code
      })
      this.speakers = data
    },
    async createSpeaker(){
      const { data } = await this.$axios.post('/speakers/createSpeaker.php', {
        app: this.$store.getters.app.code
      })
      this.speakers.push(data)
    },
    deleteSpeaker(index){
      this.speakers.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;
  .speaker-list {
    width: 70%;
  }
</style>