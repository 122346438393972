<template>
  <div>
    <NavBar text="Toutes les plateformes" link="/" :has-nav="false"/>
    <div class="content">
      <div class="left">
        <iframe :src="'https://' + $store.getters.app.url" frameborder="0"></iframe>
        <div class="controls">
          <div class="button control" @click="() => changeState('preshow')" :class="{active: state == 'preshow'}">
            Preshow
          </div>
          <div class="button control" @click="() => changeState('show')" :class="{active: state == 'show'}">
            Show
          </div>
          <div class="button control" @click="() => changeState('replay')" :class="{active: state == 'replay'}">
            Replay
          </div>
          <div class="button control" @click="() => changeState('postshow')" :class="{active: state == 'postshow'}">
            Postshow
          </div>
        </div>
      </div>
      <div class="right">
        <router-link tag="div" to="/content" class="button">
          Contenus
        </router-link>
        <router-link tag="div" to="/users" class="button">
          Utilisateurs
        </router-link>
        <router-link tag="div" to="/speakers" class="button">
          Intervenants
        </router-link>
        <router-link tag="div" to="/documents" class="button">
          Documents
        </router-link>
        <router-link tag="div" to="/agenda" class="button">
          Agenda
        </router-link>
        <router-link tag="div" to="/moderation" class="button">
          Modération
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
export default {
  name: "AppHome",
  components: {NavBar},
  async mounted(){
    const { data } = await this.$axios.post('/state/getState.php', {
      app: this.$store.getters.app.code
    })
    this.state = data.stateValue
  },
  data(){
    return {
      state: null
    }
  },
  methods: {
    async changeState(state){
      const { data } = await this.$axios.post('/state/updateState.php', {
        app: this.$store.getters.app.code,
        stateValue: state
      })
      this.state = state
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;
.button {
  padding: 5px 10px;
  border: solid 2px $blue;
  text-align: center;
  font-weight: 700;
  color: $blue;
  border-radius: 100px;
  cursor: pointer;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.button:hover {
  color: white;
  background-color: $blue;
}

.control {
  width: 20%;
}

.active {
  color: white;
  background-color: $blue;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 2vh;
}
  .content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      width: 70%;

      iframe {
        width: 100%;
        height: 60vh;
      }
    }
    .right {
      width: 20%;
    }
  }
</style>