import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    config: null,
    state: null
  },
  getters: {
    user: state => {
      return state.user
    },
    config: state => {
      return state.config
    },
    state: state => {
      return state.state
    }
  },
  mutations: {
    SET_USER: (state, payload) => {
      state.user = payload
    },
    SET_CONFIG: (state, payload) => {
      state.config = payload
    },
    SET_STATE: (state, payload) => {
      state.state = payload
    },
  },
  actions: {
    setUser: ({ commit }, payload) => {
      commit('SET_USER', payload)
    },
    setConfig: ({ commit }, payload) => {
      commit('SET_CONFIG', payload)
    },
    setState: ({ commit }, payload) => {
      commit('SET_STATE', payload)
    }
  },
  modules: {
  }
})
