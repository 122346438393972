<template>
  <div class="documents">
    <NavBar text="Accueil de la plateforme" link="/app-home" :has-nav="true"/>
    <div class="document-list">
      <div class="button" @click="createDocument">Ajouter un document</div>
      <Document v-for="(document, index) in documents" :key="document.idDocument" :document="document" v-on:delete="() => deleteDocument(index)"/>
     </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import Document from "../components/Document";
export default {
  name: "Documents",
  components: {NavBar, Document},
  data(){
    return {
      documents: []
    }
  },
  mounted(){
    this.getDocuments()
  },
  methods: {
    async getDocuments(){
      const { data } = await this.$axios.post('/documents/getDocuments.php', {
        app: this.$store.getters.app.code
      })
      this.documents = data
    },
    async createDocument(){
      const { data } = await this.$axios.post('/documents/createDocument.php', {
        app: this.$store.getters.app.code
      })
      this.documents.push(data)
    },
    deleteDocument(index){
      this.documents.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;
.document-list {
  width: 70%;
}
</style>