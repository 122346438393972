<template>
  <div>
    <NavBar text="Accueil de la plateforme" link="/app-home" :has-nav="true"/>
    <div class="form" v-if="config != null">
      <div class="field">
        <p>Titre de l'évènement</p>
        <input type="text" v-model="config.title" @input="updateConfig">
      </div>
      <div class="field">
        <p>Sous-titre de l'évènement</p>
        <input type="text" v-model="config.subtitle" @input="updateConfig">
      </div>
      <div class="field">
        <p>Dates et heures (page de login)</p>
        <input type="text" v-model="config.login_dt" @input="updateConfig">
      </div>
      <div class="field">
        <p>Dates et heures (page de live)</p>
        <input type="text" v-model="config.live_dt" @input="updateConfig">
      </div>
      <div class="field">
        <p>Dates et heures (page preshow)</p>
        <input type="text" v-model="config.preshow_dt" @input="updateConfig">
      </div>
      <div class="field">
        <p>Date début replay</p>
        <input type="text" v-model="config.replay_start" @input="updateConfig">
      </div>
      <div class="field">
        <p>Date fin replay</p>
        <input type="text" v-model="config.replay_end" @input="updateConfig">
      </div>
      <div class="field">
        <p>Lien du stream</p>
        <input type="text" v-model="config.stream_link" @input="updateConfig">
      </div>
      <div class="field">
        <p>Lien du replay</p>
        <input type="text" v-model="config.replay_link" @input="updateConfig">
      </div>
      <div class="field">
        <p>Lien du questionnaire de satisfaction</p>
        <input type="text" v-model="config.mood_link" @input="updateConfig">
      </div>
      <div class="field">
        <p>Lien du slido</p>
        <input type="text" v-model="config.slido_link" @input="updateConfig">
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
export default {
  name: "Content",
  components: {NavBar},
  data(){
    return {
      config: null
    }
  },
  async mounted(){
    const { data } = await this.$axios.post('/config/getConfig.php', {
      app: this.$store.getters.app.code
    })
    this.config = data
  },
  methods: {
    async updateConfig(){
      delete this.config.idConfig
      delete this.config.has_slido

      await this.$axios.post('/config/updateConfig.php', {
        app: this.$store.getters.app.code,
        config: this.config
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  $blue: #1A2A6C;
  $red: #B21F1F;
  $orange: #D15F25;
  $yellow: #FDBB2D;

  .form {
    width: 50%;
  }

  .field {
    width: 100%;
    margin-bottom: 30px;

    p {
      font-weight: 700;
      color: $blue;
      margin-bottom: 15px;
      font-size: 14px;
    }

    input {
      width: 100%;
      padding: 5px 10px;
      border-radius: 5px;
      border: solid 1px #AAAAAA;
    }
  }
</style>