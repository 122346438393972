<template>
  <div>
    <header>
      <div class="bar">
        <p class="mainTitle">Novartis Streaming</p>
        <p class="appName" v-if="$store.getters.app">{{ $store.getters.app.name}}</p>
      </div>
      <p class="deco" @click="logout">Déconnexion</p>
    </header>
    <div class="clearfix"></div>
  </div>
</template>

<script>
export default {
  name: "HeadBar",
  methods: {

  }
}
</script>

<style lang="scss" scoped>
$blue: #1A2A6C;
$red: #B21F1F;
$orange: #D15F25;
$yellow: #FDBB2D;
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    margin-bottom: 4vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    .bar {
      height: 8vh;
      width: 85%;
      border-bottom-right-radius: 1000px;
      background: linear-gradient(to right, $blue 0%, $red 25%, $orange 50%, $yellow 75%);
      display: flex;
      align-items: center;
      padding-left: 1vw;

      .mainTitle {
        font-size: 1.6rem;
        color: white;
        font-weight: 700;
      }

      .appName {
        margin-left: 45px;
        font-weight: 300;
        font-size: 18px;
        color:white;
      }
    }
    .deco {
      color: $blue;
      font-weight: 700;
      padding-right: 1vw;
      cursor: pointer;
    }
  }

.clearfix {
  height: 12vh;
}
</style>